<div class="mt-20 auction-search-container">
    
    <app-auction-search-filter [queryParams]="queryParams" (searchClickEvent)="onFilterEvent($event)"></app-auction-search-filter>
    <sharx-toggle [labelBefore]="'Grid'" [labelAfter]="'Lista'" [localStorageKey]="'tipo_exibicao'" (onToggleChange)="onToggleChange($event)"></sharx-toggle>
    <app-auction-search-list [auctionItens]="auctionItens" [typeExibition]="typeExibition()"></app-auction-search-list>
    
    <div class="flex flex-row justify-center pt-6">
        <sharx-paginator [paginatedResult]="paginatedResult" (onPageChange)="onPageChange($event)"></sharx-paginator>
    </div>
</div>
