import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { map, Observable, of, pipe, switchMap } from "rxjs";
import { FilterDto, RealState, RealStateMock } from "./models/real-estate.model";
import { checkNull, setUrlParams } from "app/shared/functions";
import BaseService from "../common/base-service";
import { JsonDataService } from "../json/json-data.service";
import RetornoPaginado from "../common/retorno-paginado.model";
import ResultList from "../common/result-list.model";

@Injectable({
	providedIn: 'root',
})
export class AuctionSearchService extends BaseService {
	dadosJson: any;
	constructor(
		private jsonDataService: JsonDataService,
		protected override _httpClient: HttpClient) {
		super(_httpClient);
	}

    get baseUrl(): string {
		return `${environment.PUBLIC_API_URL}${environment.gateway.auction_search.baseUrl}`
	}

    
	filterRealState(filtro: FilterDto): Observable<any> {

		return this.jsonDataService.getJsonData()
				.pipe<RetornoPaginado<any>>(map((resp) => 
				{
					let expression = (x:RealStateMock) => x.avaliacao >= filtro.range1 && x.avaliacao <= filtro.range2;
					let expressionProfile = (x:RealStateMock) => !checkNull(filtro.profile) ? x.nomTipoImovel === filtro.profile! : true;
					let expressionStatus = (x:RealStateMock) => !checkNull(filtro.status) ? x.status === filtro.status! : true;
					
					let result = (resp.imoveis as RealStateMock[]).filter(expression).filter(expressionProfile).filter(expressionStatus);
					
					let resultList = {items: result.slice((filtro.page - 1) * 10, ((filtro.page - 1) * 10) + 10), count:filtro.pageSize, currentPage:filtro.page,perPage:filtro.pageSize,total:result.length,totalPages:Math.floor(result.length/10) + (result.length%10>0 ? 1:0)} as ResultList<any>
					return new RetornoPaginado(resultList, resultList.total, resultList.totalPages);
				}));
	
		const url = new URL(`${this.baseUrl}filterImoveis`);
		if (!checkNull(filtro)) {
			setUrlParams(url, filtro);
		}
		return this.getPaginado<any>(`${url.href}`);
	}
}